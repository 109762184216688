import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import headLogo from '../../images/MairaLareTransparent.png';
import Icon from '@mdi/react';
import { mdiMenuDown } from '@mdi/js';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';


export const Navbar = () => {
    const history = useHistory();
    const dropDownRef = useRef(null);// Create ref for dropdown container
    const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);


    const useOnHoverOutside = (ref, handler) => {
        useEffect(
            () => {
              const listener = (event) => {
                if (!ref.current || ref.current.contains(event.target)) {
                  return;
                }
                handler(event);
              };
              document.addEventListener("mouseover", listener);
              return () => {
                document.removeEventListener("mouseout", listener);
              };
            },
            [ref, handler]
          );


    }

    //function to close dropdown
    const closeHoverMenu = () => {
        setMenuDropDownOpen(false);
    }



    useOnHoverOutside(dropDownRef, closeHoverMenu); //calling hook

    useEffect(() => {
        console.log('isOpen?', isMenuDropDownOpen);
    },[isMenuDropDownOpen])


    const DetectionDropDown = () => {

        return(
            <div className={styles.dropDownContainer}>
                <div className={styles.dropMenuBtn}>
                    Firearms Detection
                </div>
                <div className={styles.dropMenuBtn}>
                    Narcotics Detection
                </div>
            </div>
        )
    }
    /*
     <div className={styles.dropBtnContainer} ref={dropDownRef}>
                        <div className={styles.page}onClick={() => history.push('/branding')}  onMouseOver={()=> setMenuDropDownOpen(true)}>Detection Services<Icon path ={mdiMenuDown} className ={styles.menuDown}/></div>
                        {isMenuDropDownOpen && <DetectionDropDown/>}
                        </div>   */

    return(
        <div className={styles.main}>

            <div className={styles.row}>
                <div className={styles.logo}
                    onClick={() => history.push('/')}>
                    <img src={headLogo} style={{height:'100%', width: '100%'}} />
                </div>
                    <div className={styles.pages}>
                        <div className={styles.page} onClick={() => history.push('/menu')}>Menu</div>
                                            
                        <div className={styles.page}onClick={() => history.push('/order')}>Order</div>
                        <div className={styles.page}onClick={() => history.push('/our-story')}>Our Story</div>
                        <div className={styles.page}onClick={() => history.push('/contact')}>Contact</div>
                    </div>



                   
             
            </div>

        </div>

    )
}