//import { Menu } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Contact } from '../Contact/Contact';
import { Home } from '../Home';
import { LocationBanner } from '../LocationBanner/LocationBanner';
import { Menu } from '../Menu/Menu';
import { Navbar } from '../Navbar/Navbar';
import { NavbarMobile } from '../NavbarMobile/NavbarMobile';
import { Order } from '../Order/Order';
import { OurStory } from '../OurStory/OurStory';

import styles from './styles.module.css';
import ReactGA from "react-ga4";
import reportWebVitals from "../../reportWebVitals";





export const App = () => {
    const [mobileBool, setMobileBool] = useState(false);
    //const prevMobileBool = usePre

    const [windowDimension, setWindowDimension] = useState(720);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const isMobile = windowDimension <= 730;


    useEffect(() => {
        // console.log(isMobile);
        if (isMobile !== mobileBool) {
            setMobileBool(isMobile);
        };

        return isMobile;

    }, [isMobile]);

    ReactGA.initialize("G-THT60E7H4R");

    const SendAnalytics = () => {
        ReactGA.send({
            hitType: "pageView",
            page: window.location.pathname,
        });
    }
    reportWebVitals(SendAnalytics);


    return(
        <div className={styles.main}>
            <LocationBanner/>
             {isMobile ? (
                <NavbarMobile />
            ) : (
                <Navbar />

            )}
        
            <Switch>
            <Route exact path='/contact' component ={Contact}/>
            <Route exact path='/menu' component ={Menu}/>
            <Route exact path='/our-story' component={OurStory}/>
            <Route exact path='/order' component={Order}  />
                <Route path={['/']} component={Home}/>
                
                
            </Switch>
        </div>
    )
}