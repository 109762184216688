import styles from './styles.module.css';
import rehan from '../../images/674e5003-5217-4b60-aa79-1a91b6c7268a.JPG';
import mairaCrew from '../../images/o.jpg';
import yelp from '../../images/YELP_BIG.png';
import Icon from '@mdi/react';
import { mdiFacebook } from '@mdi/js';

export const OurStory = () => {
    const openLink = (url) => {
        window.open(url, '_blank'); // '_blank' opens the link in a new tab/window
    };

    return (
        <div className={styles.main}>
            <div className={styles.container}>

                <div className={styles.titleBanner}
                >our story</div>

                <div className={styles.strip}>


                    <div className={styles.aboutSect}>
                        <div classname={styles.aboutTitle2}>About</div>
                        <div className={styles.aboutTxt}>Known for our friendly staff and our passion for delicious food, Maira offers authentic Mediterranean cuisine with an Indian and Lebanese influence. Come to Maira to pick up a quick lunch, or dine in our no-frills setting, and see why our customers rave about our excellent food and fantastic prices!</div>
                    </div>

                    <div className={styles.rightImgCont}>
                        <img className={styles.rehan} src={rehan} />
                        <div className={styles.imgSub}>Rehan Ahmed - Owner </div>
                    </div>

                    <div className={styles.leftImgCont}>
                        <img className={styles.mairaTeam} src={mairaCrew} />
                        <div className={styles.imgSub}>Maira Mediterranean Grill Team</div>
                    </div>

                </div>

                <div className={styles.connectBanner}>
                    <div className={styles.connectTxt}>
                        Connect with Us!
                    </div>
                    <Icon className={styles.fb} path={mdiFacebook} size={2} onClick={() => { openLink('https://www.facebook.com/maira.mediterranean.grill') }} />
                    <img className={styles.yelp} src={yelp} onClick={() => { openLink('https://www.yelp.com/biz/maira-mediterranean-grill-louisville') }} />
                </div>

                <div className={styles.strip}>
                    <div className={styles.aboutSect2}>
                        <div classname={styles.aboutTitle}>Halal Meat</div>
                        <div className={styles.aboutTxt}>
                            At Maira Mediterranean Grill, we serve Halal Meat! Halal food adheres to Islamic law, the word "halal" is Arabic for "lawful" or "permitted". Traditional halal meat requires the animals to be alive and healthy before they are blessed and then killed by hand. Halal meat tastes identical to traditional meat. It is more religious than it is culinary, as the process to create halal meat imparts no special flavor qualities. Cooking and consuming halal meat results in healthy meat with a delicious texture.
                        </div>
                    </div>


                </div>

            </div>

        </div>
    )
}