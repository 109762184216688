import styles from './styles.module.css';

export const Contact = () => {

    return (
        <div className={styles.main}>
            <div className={styles.container}>

                <div className={styles.titleBanner}
                >contact us</div>

                <div className={styles.row}>
                    <div className={styles.txt}>We Serve Halal Meat</div>
                    <div className={styles.boldTxt}>502-891-0388</div>
                    <div className={styles.txt}>We Offer Catering</div>
                </div>

                <div className={styles.row}>
                    <div className={styles.smallTxt}>Mon-Fri -   10:30am - 8:30pm </div>
                    <div className={styles.smallTxt}>Sat -  11:00am - 8:30pm</div>
                    <div className={styles.smallTxt}>Sun -  Closed</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.collumn}>
                    <div className={styles.boldTxt}>Maira Mediterranian Grill</div>
                    <div className={styles.boldTxt}>4000 Dutchmans Ln, Louisville, KY 40207</div>
                    </div>
                </div>
                
                </div>

            </div>
            )
}