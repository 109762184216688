import styles from './styles.module.css';

export const Menu = () => {
    /*
    <div className={styles.collumn}>
                            <div className={styles.itemRow}>
                                <div className={styles.itemCollumn}>
                                    <div className={styles.itemTitle}></div>
                                    <div className={styles.itemDesc}></div>
                                </div>
                                <div className={styles.itemPrice}></div>
                            </div>
                        </div>
                        */

    return (
        <div className={styles.main}>
            <div className={styles.container}>

                <div className={styles.titleBanner}
                >menu</div>

                <div className={styles.italic}>*Prices subject to change*</div>

                <div className={styles.menuSubTitle}>Appetizers & Soups</div>
                <div className={styles.collumnRow}>
                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Hummus w/ Pita</div>
                                <div className={styles.itemDesc}>Dip made from chickpeas, garlic, lemon & sesame sauce (tahini)</div>
                            </div>
                            <div className={styles.itemPrice}>$7.50</div>
                        </div>


                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Baba Ganoush w/ Pita</div>
                                <div className={styles.itemDesc}>Oven-baked eggplant mixed w/ garlic tahini topped w/ olive oil</div>
                            </div>
                            <div className={styles.itemPrice}>$6.99</div>
                        </div>


                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Lentil Soup</div>
                                <div className={styles.itemDesc}>Delicious mixed lentils cooked w/ herbs & spices</div>
                            </div>
                            <div className={styles.itemPrice}>$4.50</div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Stuffed Grape Leaves</div>
                                <div className={styles.itemDesc}>Mixture of cooked rice, herbs and spices</div>
                            </div>
                            <div className={styles.itemPrice}>$8.95</div>
                        </div>

                    </div>



                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Falafel (4) w/ Pita</div>
                                <div className={styles.itemDesc}>Seasoned fried vegetable patty, served w/ tahini</div>
                            </div>
                            <div className={styles.itemPrice}>$6.50</div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Spinach Pie (2 pc)</div>
                                <div className={styles.itemDesc}>Turnover stuffed w/ spinach & spices</div>
                            </div>
                            <div className={styles.itemPrice}>$5.00</div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Kibee Starter</div>
                                <div className={styles.itemDesc}>Spiced ground meat annd bulgur wheat</div>
                            </div>
                            <div className={styles.itemPrice}>$9.95</div>
                        </div>
                    </div>
                </div>
                <div className={styles.menuSubTitle}>Salads & Vegetarian Dishes</div>
                <div className={styles.collumnRow}>
                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Greek Salad</div>
                                <div className={styles.itemDesc}>Greek house dressing, ripe black olives, & feta cheese</div>
                            </div>
                            <div className={styles.itemPrice}>$7.50</div>
                        </div>


                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Arabic Salad</div>
                                <div className={styles.itemDesc}>Cucumber, tomatoes, parsley, lemon juice & olive oil</div>
                            </div>
                            <div className={styles.itemPrice}>$6.95</div>
                        </div>


                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Greek Salad w/ Chicken or Beef
                                </div>
                                <div className={styles.itemDesc}>Greek house dressing, ripe black olives, & feta cheese w/ chicken or beef marinated in light spices</div>
                            </div>
                            <div className={styles.itemPrice}>$13.99</div>
                        </div>


                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>House Salad w/ Chicken or Beef
                                </div>
                                <div className={styles.itemDesc}>Fresh garden vegetables w/ house dressing and chicken or beef marinated in light spices</div>
                            </div>
                            <div className={styles.itemPrice}>$12.99</div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Jerusalem Salad
                                </div>
                                <div className={styles.itemDesc}>Cucumber, tomatoes, parsley, lemon juice, olive oil, mint, and tahini sauce</div>
                            </div>
                            <div className={styles.itemPrice}>$8.95</div>
                        </div>



                    </div>



                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Tabouleh</div>
                                <div className={styles.itemDesc}>World-famous Lebanese salad. Diced tomatoes, onion, parsley, cracked wheat, & lemon.</div>

                            </div>
                            <div className={styles.itemPrice}>$8.75</div>
                        </div>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Mujadarah</div>
                                <div className={styles.itemDesc}>Lentils & rice cooked topped w/ fried onions & served w/ yogurt sauce.</div>

                            </div>
                            <div className={styles.itemPrice}>$10.99</div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Falafel Platter</div>
                                <div className={styles.itemDesc}>4 pieces w/ house salad, hummus, rice, & tahini.</div>

                            </div>
                            <div className={styles.itemPrice}>$10.99</div>
                        </div>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Vegetarian Platter</div>
                                <div className={styles.itemDesc}>Select (4) from: hummus, falafel, baba ganoush, rice, tabouleh, mujadareh, spinach pie.</div>

                            </div>
                            <div className={styles.itemPrice}>$13.99</div>
                        </div>




                    </div>
                </div>


                <div className={styles.menuSubTitle}>Main Dishes</div>
                <div className={styles.collumnRow}>

                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Shish Kabob</div>
                                <div className={styles.itemDesc}>Skewer of charbroiled tenderloin, onion, & green pepper. Side of hummus.</div>

                            </div>
                            <div className={styles.itemPrice}>
                                $13.99
                            </div>
                            <div className={styles.itemPrice}>

                                Extra Skewer $8.00
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Kafta Kabob</div>
                                <div className={styles.itemDesc}>Two skewers ground beef w/ onion, parsley, & spices. Side of hummus.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $12.99
                            </div>
                            <div className={styles.itemPrice}>

                                Extra Skewer $3.50
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Shish Tawook</div>
                                <div className={styles.itemDesc}>Skewer of spiced, charbroiled chicken breast. Side of garlic sauce.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $12.99

                            </div>
                            <div className={styles.itemPrice}>for
                                Extra Skewer $5.00
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Fried Talapia</div>
                                <div className={styles.itemDesc}>Spiced & breaded filet. Side of tartar sauce.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $12.99
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Gyro Platter</div>
                                <div className={styles.itemDesc}>Beef & Lamb combination platter with rice, greek salad, & Tzatziki sauce</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $13.99
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Maira Special</div>
                                <div className={styles.itemDesc}>Feeds two. Skewers & chicken kabob, ketta kabob, beef, chicken, lamb kabob and falafel</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $46.75
                            </div>
                        </div>

                    </div>

                    <div className={styles.collumn}>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Chicken Shawarma</div>
                                <div className={styles.itemDesc}>Chicken marinated in light spices. Side of garlic sauce.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $12.99<br />

                            </div>
                            <div className={styles.itemPrice}>

                                Extra Meat $4.50
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Beef Shawarma</div>
                                <div className={styles.itemDesc}>Beef marinated in light spices. Side of tahini.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $12.99
                            </div>
                            <div className={styles.itemPrice}>

                                Extra Meat $4.50
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Athenian Broiled Chicken</div>
                                <div className={styles.itemDesc}>Chicken breast sautéed w/ creamy garlic sauce, tomato, & spices.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $13.99
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Beef & Chicken Combo</div>
                                <div className={styles.itemDesc}>One skewer each of Kafta Kabob & Shish Tawook.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $15.99
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Lamb Chops</div>
                                <div className={styles.itemDesc}>
                                    Cooked to perfection and served with rice, wegetables and a choice & salad.
                                </div>
                            </div>
                            <div className={styles.itemPrice}>
                                $19.99
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Lamb Kabobs</div>
                                <div className={styles.itemDesc}>
                                    Exeptional lamb kabobs saved with rice, grilled vegetablesand a choice of a salad.
                                </div>
                            </div>
                            <div className={styles.itemPrice}>
                                $13.99
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.menuSubTitle}>Kids Menu</div>
                <div className={styles.collumnRow}>

                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Chicken Shawarma w/ Rice</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $7.99
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Beef Shawarma w/ Rice</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $7.99
                            </div>
                        </div>

                    </div>
                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Hamburger or Chicken Burger w/ Fries</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $9.49
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Fried Chicken Tender Strips w/ Fries</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $8.99
                            </div>
                        </div>

                    </div>
                </div>


                <div className={styles.menuSubTitle}>Sandwiches</div>
                <div className={styles.collumnRow}>

                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Kafta Kabob</div>
                                <div className={styles.itemDesc}>Skewer of ground beef, onion & spices w/ hummus.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $8.99
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Chicken Shawarma</div>
                                <div className={styles.itemDesc}>Chicken marinated in light spices, topped w/ garlic sauce.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $8.99
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Beef Shawarma</div>
                                <div className={styles.itemDesc}>Beef marinated in light spices, topped w/ tahini.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $8.99
                            </div>
                        </div>


                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Vegatable Paradise</div>
                                <div className={styles.itemDesc}>Hummus, tabouleh salad, pickles, and fetta cheese.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $8.99
                            </div>
                        </div>
                    </div>

                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Falafel</div>
                                <div className={styles.itemDesc}>Tried ground dried vegetables topped w/ tahini.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $8.99
                            </div>
                        </div>


                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Fried Talapia</div>
                                <div className={styles.itemDesc}>Whole filet of fish w/ tartar sauce & hummus.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $8.99
                            </div>
                        </div>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Gyro</div>
                                <div className={styles.itemDesc}>Beef & Lamb combination wrapped w/ Tzatziki, Lettuce, Tomato & Onion</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $9.99
                            </div>
                        </div>

                    </div>
                </div>
                <div className={styles.menuSubTitle}>Side Orders</div>
                <div className={styles.collumnRow}>

                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Rice</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $2.50
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Garlic Sauce</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $0.75
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Hummus or Tahini</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $0.75
                            </div>
                        </div>
                    </div>

                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>French Fries</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $3.99
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Pita</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $0.75
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Pickles</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $2.00
                            </div>
                        </div>

                    </div>
                </div>
                <div className={styles.menuSubTitle}>Beverages & Desserts</div>
                <div className={styles.collumnRow}>

                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Bottled Drinks</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $2.75
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Bottled Water</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $1.25
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Masala Tea</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $3.00
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Mango Lassi</div>
                                <div className={styles.itemDesc}></div>
                            </div>
                            <div className={styles.itemPrice}>
                                $3.00
                            </div>
                        </div>
                    </div>

                    <div className={styles.collumn}>
                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Baklava</div>
                                <div className={styles.itemDesc}>Thin layers of phyllo dough stuffed w/ mixed nuts, topped w/ honey-lemon syrup.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $2.50
                            </div>
                        </div>

                        <div className={styles.itemRow}>
                            <div className={styles.itemCollumn}>
                                <div className={styles.itemTitle}>Namura</div>
                                <div className={styles.itemDesc}>Baked semolina honey cake.</div>
                            </div>
                            <div className={styles.itemPrice}>
                                $1.99
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}